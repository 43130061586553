import { NgIf, NgClass, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR, FormControlDirective, ReactiveFormsModule } from '@angular/forms';
import { IComLibsServicesProjectCampaignV3Service } from '@icom/services/projects';
import { FieldType, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile, NzUploadModule } from 'ng-zorro-antd/upload';
import { Observable, Observer, lastValueFrom } from 'rxjs';
@Component({
  selector: 'icom-libs-core-components-formly-upload',
  template: `
    <nz-form-item class="flex-col">
      <nz-form-label *ngIf="to.label" [nzRequired]="to.required">{{ to.label }}</nz-form-label>
      <div class="nz-input-group">
        <nz-upload
          class="avatar-uploader {{props.className}}"
          ngDefaultControl
          nzName="avatar"
          [formControl]="$any(formControl)" 
          [formlyAttributes]="field"
          [nzAccept]="props['accept']"
          nzName="avatar"
          [nzShowUploadList]="false"
          [nzDisabled]="props.nzDisabled || props.disabled || formControl.disabled"
          [nzMultiple]="false"
          [(nzFileList)]="fileList"
          nzListType="picture-card"
          [nzBeforeUpload]="beforeUpload"
          (nzChange)="handleUploadChange($event)">
          <ng-container *ngIf="!avatarUrl">
            <div class="flex flex-col">
              <div class="flex items-center">  
                <span class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'"></span>
                <div class="ant-upload-text ml-4">Upload</div> 
              </div>
              <p style="color: #00000073; font-size: 20px;">{{ props.width || 80 }} X {{ props.height || 80 }}</p>

            </div>
            
          
          </ng-container>
            <img class="object-contain w-full h-full" style="    object-fit: contain;" *ngIf="avatarUrl" [src]="avatarUrl" />
              </nz-upload> 
        </div>

        <div class="ant-form-item-explain" [ngClass]="{'ant-form-item-explain-connected' : !showErr(field)}">
          <div class="ant-form-item-explain-error">
            <formly-validation-message [field]="field"></formly-validation-message>
          </div>
        </div>
    </nz-form-item>
  `,
  standalone: true,
  imports: [
    NzUploadModule,
    NzButtonModule,
    NzIconModule,
    FormlyModule,
    NgIf,
    NzFormModule,
    NgClass,
    ReactiveFormsModule,
    FormlyNgZorroAntdModule,
    JsonPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: DefaultValueAccessor, useExisting: IcomLibsCoreComponentsFormlyUploadComponent },
    { provide: NG_VALUE_ACCESSOR, useExisting: IcomLibsCoreComponentsFormlyUploadComponent, multi: true },
    FormControlDirective
  ]
})
export class IcomLibsCoreComponentsFormlyUploadComponent extends FieldType implements OnInit {
  protected fileList: NzUploadFile[] = [];
  protected loading = false;
  protected avatarUrl?: string;

  private campaignService = inject(IComLibsServicesProjectCampaignV3Service);
  private msg = inject(NzMessageService);
  private cdr = inject(ChangeDetectorRef);

  ngOnInit() {
    if (this.formControl && (this.formControl as any)['defaultValue']) {
      this.avatarUrl = (this.formControl as any)['defaultValue'];
    }
  }

  protected async handleUploadChange(event: any) {
    const formData = new FormData();
    formData.append('image', event.file.originFileObj as File);
    try {
      this.loading = true;
      const result: any = await lastValueFrom(this.campaignService?.uploadImage(formData));
      this.formControl.setValue(result?.data?.path || '');
      this.getBase64(event.file!.originFileObj!, (img: string) => {
        this.loading = false;
        this.avatarUrl = img;
        this.cdr.detectChanges();
      });

    } catch (err) {
    } finally {
      this.loading = false;
    }
  }

  protected showErr(field: FormlyFieldConfig) {
    return field.formControl && field.formControl.invalid && field.formControl.touched;
  }

  beforeUpload = (file: NzUploadFile) => {
    return new Observable((observer: Observer<boolean>) => {
      const isJPG = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg';
      if (!isJPG) {
        this.msg.error('Hệ thống chỉ hỗ trợ định dạng file png, jpg, jpeg.');
        observer.complete();
        return;
      }
      const isLt2M = (file?.size  || 0) < this.props['size'];
      if (!isLt2M) {
        this.msg.error(`Hệ thống chỉ hỗ trợ file có dung lượng nhỏ hơn ${this.formatSize(this.props['size'] as number)}`);
        observer.complete();
        return;
      }
      this.checkImageDimension(file).then(dimensionRes => {
        if (!dimensionRes) {
          this.msg.error(`Hệ thống chỉ hỗ trợ file có kích thước nhỏ hơn ${this.props.width || 80} pixel X ${this.props.height || 80} pixel`);
          observer.complete();
          return;
        }
        observer.next(isJPG && isLt2M && dimensionRes);
        observer.complete();
      });
    });
  }

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  handleChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        this.getBase64(info.file!.originFileObj!, (img: string) => {
          this.loading = false;
          this.avatarUrl = img;
        });
        break;
      case 'error':
        this.msg.error('Network error');
        this.loading = false;
        break;

    }
  }

  private checkImageDimension(file: NzUploadFile | undefined): Promise<boolean> {
    return new Promise(resolve => {
      const img = new Image(); // create image
      if (!file) {
        return;
      }
      img.src = window.URL.createObjectURL(file as any);
      img.onload = () => {  
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src);
        resolve((width <= (this.props.width || 80)) && (height <= (this.props.height || 80)));
      };
    });
  }

  private formatSize(sizeInBytes: number): string {
    if (sizeInBytes < 1024) {
        return `${sizeInBytes} B`;
    } else if (sizeInBytes < 1024 * 1024) {
        return `${(sizeInBytes / 1024).toFixed(2)} KB`;
    } else {
        return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
    }
}
}