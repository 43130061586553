import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
	public setItem(key: string, data: unknown): void {
		localStorage.setItem(key, JSON.stringify(data));
	}

	public getItem(key: string) {
		const value = localStorage.getItem(key) ?? '';
		return value ? JSON.parse(value) : null;
	}

	public getTokenData(): any {
		const value = localStorage.getItem('token') ?? '';
		try {
			return jwt_decode(value);
		  } catch(Error) {
			return null;
		  }
	}


	public removeItem(key: string): void {
		localStorage.removeItem(key);
	}

	public clear() {
		localStorage.clear();
	}

	clearAllLocalStorage() {
		localStorage.clear();
	}
}
