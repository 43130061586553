import { Component, EventEmitter, Output, inject } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile, NzUploadModule } from 'ng-zorro-antd/upload';
import { NgIf } from '@angular/common';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { IComMessageService } from '../../../../services/common/index';
import { IComLibsServicesProjectFlashSaleService } from '../../../../services/projects/flash-sale/flash-sale.service';
import { map } from 'rxjs';
import { uploadFile } from '../../../../defines/static.define';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
@Component({
  selector: 'app-import-modal-content',
  template: `
  <span class="text-xs">
    (Tải về file mẫu:
    <a nz-button nzType="link" (click)="handlerDownloadFile($event)">Excel file</a>):
  </span>
    <nz-alert
      class="text-xs"
      nzType="warning"
      nzBanner
      [nzShowIcon]="false"
      nzMessage="Lưu ý"
      nzDescription="Hệ thống cho phép nhập tối đa 100 voucher mỗi lần từ file">
    </nz-alert>

    <nz-upload
      nzAction="URL_TO_UPLOAD_ENDPOINT"
      [(nzFileList)]="fileList"
      [nzBeforeUpload]="beforeUpload"
      (nzChange)="handleChange($event)"
      nzListType="text"
      [nzShowUploadList]="{ showRemoveIcon: true }">
      <button nz-button>
        <i nz-icon nzType="upload"></i><span class="ml-2">Chọn file dữ liệu</span>
      </button>
    </nz-upload>
   
  `,
  standalone: true,
  imports: [
    NzUploadModule,
    NgIf,
    NzIconModule,
    NzAlertModule
  ]
})
export class ImportModalContentComponent {
  fileList: NzUploadFile[] = [];
  isLoading!: boolean;

  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();

  protected IComMessageService = inject(IComMessageService);
  protected IComMessageFlashSaleService = inject(IComLibsServicesProjectFlashSaleService);
  public readonly nzModalData = inject(NZ_MODAL_DATA);

  constructor(private msg: NzMessageService) {}

  beforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean => {
    // Tạo một đối tượng file mới với trạng thái "uploading"
    // const newFile: NzUploadFile = {
    //   ...file,
    //   // status: 'uploading',
    //   // Tùy chỉnh thêm các thông tin khác nếu cần
    // };

    // Thêm file mới vào danh sách file
    this.fileList = fileList;
    return false; // Ngăn chặn việc tự động upload
  };

  handleChange(info: { file: any; fileList: any }): void {
    if (info.file.status === 'removed') {
      // Handle removed file
    }
  }

  handlerDownloadFile(e: Event) {
    e.stopPropagation();
    e.preventDefault();
    uploadFile(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/flashsale/voucher/examfile`, 'MauFileImportSanPham', this.IComMessageService, undefined, true);
  }


  handleImport() {
    this.isLoading = true;
    const formData = new FormData();
    formData.append('excel', this.fileList[0] as any);
  
    // Lặp qua các khóa của nzModalData và append vào formData
    if (this.nzModalData) {
      for (const key in this.nzModalData) {
        if (this.nzModalData[key]) { // Kiểm tra nếu giá trị không phải là null hoặc undefined
          formData.append(key, this.nzModalData[key]);
        }
      }
    }
  
    this.IComMessageFlashSaleService.uploadVoucher(formData).subscribe({
      next: (res) => {
        this.onSave.emit(res.data?.data);
        this.isLoading = false;
      },
      error: (err) => {
        this.IComMessageService.showAlertMessageError((err as HttpErrorResponse)?.error?.error_message);
        this.isLoading = false;
        this.onSave.emit(false);
      },
    });
  }
}
