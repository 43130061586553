import { ApplicationConfig, enableProdMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppModule } from './app/app.module';
import { NgxsModule } from '@ngxs/store';
import { environment } from 'libs/core/environments/environment';
// import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/ngsw-worker.js');
//   });
// // }
// }


export const appConfig: any = {
  providers: [
    // importProvidersFrom(
    //   ServiceWorkerModule.register('ngsw-worker.js', {
    //     enabled: true,
    //     registrationStrategy: 'registerWhenStable:30000'
    //   }),
    // ),
    importProvidersFrom(NgxsModule.forRoot([], {developmentMode: !environment.production})),
  ]
};

platformBrowserDynamic()
	.bootstrapModule(AppModule, appConfig)
	.catch((err) => console.error(err));
