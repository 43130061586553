import { Injectable } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { Observable, filter, map, startWith } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  breadcrumbs$: Observable<Breadcrumb[]>;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.breadcrumbs$ = this.router.events.pipe(
      map(() => this.createBreadcrumbs(this.activatedRoute.root))
    );
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      if (child.snapshot.routeConfig && child.snapshot.routeConfig.redirectTo) {
        continue;
      }
      console.log(child.snapshot)
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data['breadcrumb'];
      const isNotClick =  child.snapshot?.routeConfig?.data?.['notClick'];
      if (label) {
        breadcrumbs.push({ label, url, isNotClick });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
    console.log(breadcrumbs);
    return breadcrumbs;
  }
}

export interface Breadcrumb {
  label: string;
  url: string;
  isNotClick?: boolean;
}
