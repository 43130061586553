import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { NzInputModule } from 'ng-zorro-antd/input';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';

@Component({
  selector: 'formly-field-textarea-custom',
  template: `
   <ng-container *ngIf="!to.readonly; else viewMode">
  <textarea class="text-xs no-resize break-words max-w-md"  [rows]="5" nz-input [formControl]="$any(formControl)" [formlyAttributes]="field" [rows]="to.rows || 4"
    [placeholder]="props.placeholder || ''"></textarea>
  </ng-container>
  <ng-template #viewMode>
    <div class="text-xs break-words">
      {{ formControl.value || 'Chưa có dữ liệu' }}
    </div>
  </ng-template>
  `,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzInputModule,
    FormsModule,
    FormlyModule,
    FormlyNgZorroAntdModule
  ],
  styles: [`
  .break-words {
    word-break: break-word;
  }
  .no-resize {
  resize: none;
}
`],
})

export class FormlyFieldTextareaCustom extends FieldType {}
