import { Component, Inject, Renderer2, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'libs/core/environments/environment';

@Component({
  selector: 'icom-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.updateHeaderConfig();
    }
  }

  updateHeaderConfig() {
    console.log(environment)
    this.renderer.setProperty(
      document.querySelector('title'),
      'textContent',
      (environment as any).title || 'ahihi'
    );

    this.renderer.setProperty(
      document.querySelector('link[rel="icon"]'),
      'href',
      (environment as any).favicon ?? ''
    );
  }
}