import { JsonPipe, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';

@Component({
  selector: 'formly-array-type',
  template: `
   <nz-form-item class="flex-col" style="flex-direction: column; align-items: start">
   <nz-form-label *ngIf="props.label && props.required" [nzRequired]="true">{{ props.label }}</nz-form-label>
      <nz-form-label *ngIf="props.label && !props.required">{{ props.label }}</nz-form-label>
      <div class="flex justify-start w-full">
        <button nz-button
          *ngIf="!props.readonly && !options?.formState?.disabled && canAdd()"
          nzType="primary"
          (click)="add(undefined, props.initialModel || undefined, { markAsDirty: true })">
          <span>{{ props.addText }}</span>
        </button>
      </div>
      <div class="ant-form-item-explain-error" *ngIf="formControl.dirty && formControl.invalid && props.required">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>

      <div *ngFor="let field of field.fieldGroup; let i = index" class="row align-items-start mt-2 w-full flex">
        <formly-field class="col w-full" [field]="field"></formly-field>
        <div *ngIf="!props.readonly && field.props?.removable !== false && !field.hide" class="col-2 ml-2 text-right">
          <button nz-button
            [nzType]="'default'" [nzDanger]="true" 
            (click)="remove(i, { markAsDirty: true })">
            <span>{{ 'Xóa' }}</span>
          </button>
        </div>
      </div>
    </nz-form-item>
  `,
  standalone: true,
  imports: [
    NzDatePickerModule,
    ReactiveFormsModule,
    FormlyNgZorroAntdModule,
    FormlyModule,
    NgIf,
    NgFor,
    NzFormModule,
    NzButtonModule,
    JsonPipe
  ],
})
export class ArrayTypeComponent extends FieldArrayType implements OnInit {
  ngOnInit() {
      this.formControl.valueChanges.pipe().subscribe(values => {
        if (this.props.required) {
          this.formControl.setValidators([Validators.required]);
        } else {
          this.formControl.clearValidators();
        }
    });
  }

  canAdd(): boolean {
    return this.field && this.field.fieldGroup && this.field.fieldGroup.length < (this.props.maxLength || 99999) || true;
  }
}
