import { Component, ChangeDetectionStrategy, ViewChild, Type, inject, ChangeDetectorRef, DestroyRef } from '@angular/core';
import {
  FieldType,
  FieldTypeConfig,
  FormlyFieldConfig,
  FormlyModule,
} from '@ngx-formly/core';
import {
  FormlyFieldProps,
  FormlyNzFormFieldModule,
} from '@ngx-formly/ng-zorro-antd/form-field';
import {
  FormlyFieldSelectProps,
  FormlySelectModule,
} from '@ngx-formly/core/select';
import { AsyncPipe, CommonModule, NgClass, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { Observable, Subject, Subscription, debounceTime, distinctUntilChanged, isObservable, switchMap, take, takeUntil, throttleTime } from 'rxjs';
import { NzSelectComponent } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'formly-field-nz-select',
  template: `
    <div [class]="props.classInclude" *ngIf="!to.readonly; else readonlyTemplate">
      <nz-select
        class="max-w-md"
        [class.ng-dirty]="showError"
        [nzPlaceHolder]="'Chọn giá trị'"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [nzServerSearch]="props.nzServerSearch || false"
        [nzShowSearch]="true"
        [nzDisabled]="props.disabled"
        [nzAllowClear]="props.ignoreRemoveIcon ? false : true"
        [nzMode]="props.multiple ? 'multiple' : 'default'"
        (keydown.enter)="handleKeydown($event)"
        [nzDropdownRender]="renderTemplate"
        (nzScrollToBottom)="loadMore()"
        (nzOnSearch)="onSearch($event)"
        (ngModelChange)="props.change && props.change(field, $event)">
        <ng-container
           *ngFor="let item of resolvedOptions">
          <nz-option
            [nzValue]="$any(item)?.[props.valueProp || 'value']"
            [nzDisabled]="item.disabled"
            [nzLabel]="$any(item)?.[props.labelProp || 'label']"
          ></nz-option>
        </ng-container>

        <ng-template #renderTemplate>
          <div *ngIf="isLoading" class="loading-container absolute top-5 left-1/2">
            <nz-spin nzSize="large"></nz-spin>
          </div>
      </ng-template>

      </nz-select>
      <button *ngIf="props.functionAddNew" nz-button nzType="link" class="add-new-button" (click)="handleAddNew()">
        <span nz-icon nzType="plus" nzTheme="outline"></span> Thêm mới
      </button>

      <div *ngFor="let item of resolvedOptions" class="mt-4">
        <label [nzDisabled]="props.disabled" *ngIf="item.code === 'PHONE_NUMBER'" class="text-sm font-semibold" nz-checkbox [ngModel]="item?.['enable_edit']"
        (nzCheckedChange)="props.changeCheckbox && props.changeCheckbox(field, $event)">
          Cho phép sửa {{ item.title }}
        </label>
      </div>
    </div>
    
    <ng-template #readonlyTemplate>
      <span *ngIf="resolvedOptions && resolvedOptions?.length ">{{ getSelectedLabel(formControl.value) }}</span>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzSelectModule,
    FormlyNzFormFieldModule,
    FormlySelectModule,
    FormsModule,
    FormlyModule,
    ReactiveFormsModule,
    FormlyNgZorroAntdModule,
    NzFormModule,
    NgIf,
    FormlyNgZorroAntdModule,
    FormlyModule,
    NgIf,
    NzFormModule,
    NgClass,
    NzInputModule,
    AsyncPipe,
    NzButtonModule,
    NzSpinModule,
    NzCheckboxModule
  ],
})
export class FormlyFieldSelect extends FieldType<FieldTypeConfig> {
  resolvedOptions: any[] = [];
  protected isLoading!: boolean;
  private previousValue: any[] = [];
  protected pagination: any = {
    limit: 15,
    page: 1
  };
  searchChange$ = new Subject<any>();

  protected keySearch!: string;
  protected values!: string[];
  private subscription?: Subscription;
  private cdr = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  @ViewChild(NzSelectComponent, { static: false }) nzSelectComponent!: NzSelectComponent;

  handleKeydown(event: any): void {
    if (event.key === 'Enter' && this.nzSelectComponent.nzOpen) {
      event.preventDefault();
    }
  }

  ngOnInit(): void {
    this.searchChange$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap((term: string) => {
        this.isLoading = true;
        this.resolvedOptions = this.resolvedOptions?.map(option => ({ ...option, disabled: true }));
        this.cdr.detectChanges();
        this.keySearch = term;
        return this.searchData(term);
      })
    ).subscribe((data: any) => {
      this.isLoading = false;
      this.resolvedOptions = data?.items;
      this.pagination = data?.pagination;
      this.cdr.detectChanges();
    });

    if (isObservable(this.field?.props?.options)) {
      this.subscription = this.field?.props?.options?.pipe().subscribe(options => {
        this.resolvedOptions = options;
        console.log(cloneDeep(this.resolvedOptions))
        this.props.emitList && this.props.emitList(this.resolvedOptions, this.field);
        this.props?.functionCheckExist && this.props?.functionCheckExist(this.field, this.resolvedOptions);
        this.cdr?.detectChanges();
      });
    } else {
      this.resolvedOptions = this.field?.props?.options as any[];
      console.log(cloneDeep(this.resolvedOptions))
      this.props?.functionCheckExist && this.props?.functionCheckExist(this.field, this.resolvedOptions);
      this.props.emitList && this.props.emitList(this.resolvedOptions, this.field);
      this.cdr?.detectChanges();
    }

    this.formControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe(currentValue => {
        this.handleOptionSelection(currentValue);
        this.previousValue = currentValue || [];
      });

  }

  isHandlingSelection!: boolean;
  private handleOptionSelection(selectedValues: any[] | any): void {
    const addedValues = selectedValues?.filter(
      (val: any) => !this.previousValue?.includes(val)
    );
    const removedValues = this.previousValue?.filter(
      (val) => !selectedValues?.includes(val)
    );
    if (!Array.isArray(selectedValues)) {
      selectedValues = [selectedValues];
    }
    this.resolvedOptions.forEach(option => {
      if (selectedValues.includes(option.id)) {
        if (option?.parent_code) {
          const parentOption = this.resolvedOptions.find(opt => opt.code === option.parent_code);
          if (parentOption) {
            parentOption.checked = true;
            if (!selectedValues.includes(parentOption.id)) {
              addedValues?.length && selectedValues?.push(parentOption.id);
              this.formControl.patchValue(selectedValues, { emitEvent: false });
              this.to.readonly = true;
              setTimeout(() => {
                this.to.readonly = false;
                this.cdr.detectChanges();
              }, 50)
              this.cdr.detectChanges();
            }
          }
        }
        this.cdr.detectChanges();
      }
      const childOption = this.resolvedOptions?.find(opt => opt.code === option.child_code);
        if (childOption?.code) {
          removedValues?.length && (selectedValues = selectedValues?.filter((selectedValue: any) => selectedValue !== childOption.id));
          console.log(cloneDeep(selectedValues))
          this.formControl.patchValue(selectedValues, { emitEvent: false });
          this.to.readonly = true;
          setTimeout(() => {
            this.to.readonly = false;
            this.cdr.detectChanges();
          }, 50)
          this.cdr.detectChanges();
        }
      this.cdr.detectChanges();
    });
  }

  onSearch(searchText: string) {
    if (!this.props.nzServerSearch) {
      return;
    }
    this.searchChange$.next(searchText);
  }

  private searchData(term: string): Observable<any[]> {

    return this.props.listOptionDropdown(1, { name: term });
  }


  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  getSelectedLabel(value: any | any[]): string {
    if (!this.resolvedOptions || value == null) {
      return 'Chưa có dữ liệu';
    }

    // Xử lý khi multi = true (value là mảng)
    if (Array.isArray(value)) {
      if (!value?.length) {
        return 'Chưa có dữ liệu';
      }
      return value
        .map(val => this.getLabelForValue(val))
        .filter(label => label)
        .join(', ');
    }
    return this.getLabelForValue(value);
  }

  private getLabelForValue(val: any): string {
    const selectedOption = this.resolvedOptions.find(option => (option.id === val || option._id === val));
    return selectedOption ? (selectedOption.value || selectedOption.title || selectedOption.name) : 'Chưa có dữ liệu';
  }

  loadMore(): void {
    if (!this.props.nzServerSearch) {
      return;
    }
    if (this.pagination.total_pages === this.pagination.page) {
      return;
    }
    this.isLoading = true;
    this.resolvedOptions = this.resolvedOptions?.map(option => ({ ...option, disabled: true }));
    this.props.listOptionDropdown(this.pagination.page + 1, { search: this.keySearch }).subscribe((data: any) => {
      this.isLoading = false;
      this.resolvedOptions = [...this.resolvedOptions, ...data?.items];
      this.resolvedOptions = this.resolvedOptions?.map(option => ({ ...option, disabled: false }));
      this.pagination = data?.pagination;
      this.cdr.detectChanges();
    });
  }

  protected handleAddNew(): void {
    if (this.props.functionAddNew) {
      this.props.functionAddNew(this.field, () => {
        this.ngOnInit();
        this.cdr.detectChanges();
      });
    }
  }

}
