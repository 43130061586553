import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { ApiService } from "../../common/http.service";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})

export class IComLibsServicesProductCardService {
  constructor(private apiService: ApiService) { }

  createProduct(body = {}) : Observable<any>{
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/cards`, body);
  }
  

  updateProduct(id: number, body = {}) : Observable<any>{
    return this.apiService.put(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/products/${id}`, body);
  }

  getRegisterForm(id: string | number): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/form_registration/${id}`);
  }

  getListField(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/list_field`).pipe(
      map((res: any) => {
        return {
          ...res,
          data: res.data.map((item: any) => {
            if(item.code === 'LOCATION_PROVINCE_CODE') {
              item.child_code = 'LOCATION_DISTRICT_CODE';
            }
            return item;
          })
        }
      }
    ));
  }


  getListFieldProdcutId(productId: number): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/list_field_update_card/${productId}`);
  }
  

  updateRegisterForm(product_id:number, body: any = {}): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/form_registration/${product_id}`, body);
  }

  getGiaoDichProduct(product_id: number): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/inventory-product-items/${product_id}`);
  }

  updateGiaoDichCardProduct(product_id: number, body: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/customer_card/${product_id}`, body);
  }
  
  getCardManagement(product_id: string): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/card_management/${product_id}`);
  }

  updateCardManagement(product_id: string, body: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/card_management/${product_id}`, body);
  }

  getDistricts(city_code: string): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/list_district/${city_code}`);
  }
}
