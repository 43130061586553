import { CommonModule, NgIf, NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FieldType, FieldWrapper, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';


@Component({
  selector: 'app-password-toggle-wrapper',
  template: `
    <nz-form-item class="ant-form-item ant-row ant-form-item-required {{ to.className }}">
      <nz-form-label *ngIf="to.label" [nzRequired]="to.required">{{ to.label }}</nz-form-label>
      <nz-form-control class="ant-form-item-control">
        <nz-input-group [nzSuffix]="suffixTemplate ?? ''">
        <input nz-input [type]="show ? 'text' : 'password'" [formControl]="$any(formControl)" [formlyAttributes]="field" />
        </nz-input-group>
        <ng-template #suffixTemplate>
          <span
            class="cursor-pointer"
            nz-icon
            [nzType]="show ? 'eye-invisible' : 'eye'"
            (click)="show = !show"
          ></span>
        </ng-template> 
        <div class="ant-form-item-explain" [ngClass]="{'ant-form-item-explain-connected' : !showErr(field)}">
          <div class="ant-form-item-explain-error">
            <formly-validation-message [field]="field"></formly-validation-message>
          </div>
        </div>
      </nz-form-control>
    </nz-form-item>
  `,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzInputModule,
    FormsModule,
    NgIf,
    FormlyNgZorroAntdModule,
    FormlyModule,
    NgClass,
    NzInputModule,
    NzIconModule,
    NzFormModule
  ]
})
export class PasswordToggleWrapperComponent extends FieldType {
  show = false;

  showErr(field: FormlyFieldConfig) {
    return field.formControl && field.formControl.invalid && field.formControl.touched;
  }
  toggleVisibility() {
    this.show = !this.show;
  }
}