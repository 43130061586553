
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiService } from "../../common/http.service";

@Injectable({
  providedIn: 'root',
})

export class IComLibsServicesProjectCampaignV3Service {
  constructor(private apiService: ApiService) { }

  list(data: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/list`, data) as Observable<any>;
  }

  detail(data: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/detail`, data) as Observable<any>;
  }

  uploadImage(data: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/upload-icon`, data,  {
      'Content-Type': 'clear'
    }) as Observable<any>;
  }


  addEditCampaign(data: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/general`, data) as Observable<any>;
  }

  getTypeMissions(): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/gup2-enum`, {
      "group": "1999"
  }) as Observable<any>;
  }

  getActionMission(): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/action-tag`) as Observable<any>;
  }

  getContributor(): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/contributor`) as Observable<any>;
  }

  getPool(): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/pool`) as Observable<any>;
  }

  getSubPool(body: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/sub-pool`, body) as Observable<any>;
  }

  getRewardLevel(): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/gup2-enum`, {
      "group": "2999"
  }) as Observable<any>;
  }

  getRewardForm(): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/gup2-enum`, {
      "group": "3999"
  }) as Observable<any>;
  }

  getMessageTemplate(): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/message-template`) as Observable<any>;
  }

  getTypeReward(): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/gup2-enum`, {
      "group": "5999"
  }) as Observable<any>;
  }

  getListStatus(): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/gup2-enum`, {
      "group": "8999"
  }) as Observable<any>;
  }

  getListTimeStart(): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/gup2-enum`, {
      "group": "6999"
  }) as Observable<any>;
  }

  getListTimeEnd(): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/gup2-enum`, {
      "group": "7999"
  }) as Observable<any>;
  }

  getListOrderAction(): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/gup2-enum`, {
      "group": "999"
  }) as Observable<any>;
  }

  getListOrderActionBy(): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/gup2-enum`, {
      "group": "4999"
  }) as Observable<any>;
  }

  getListActionType(): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/action-type`) as Observable<any>;
  }

  getListActionParam(id: string): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/action-param`, {action_type_code: id}) as Observable<any>;
  }


}

