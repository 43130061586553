<nz-form-item
  class="ant-form-item ant-row ant-form-item-required {{ to.className }}"
>
  <nz-form-label *ngIf="to.label" [nzRequired]="to.required">{{
    to.label
  }}</nz-form-label>
  <nz-form-control class="ant-form-item-control">
    <nz-input-group nzPrefixIcon="search">
      <input
        type="text"
        nz-input
        [formControl]="$any(formControl)"
        [formlyAttributes]="field"
      />
    </nz-input-group>

    <div
      class="ant-form-item-explain"
      [ngClass]="{ 'ant-form-item-explain-connected': !showErr(field) }"
    >
      <div class="ant-form-item-explain-error">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>
    </div>
  </nz-form-control>
</nz-form-item>
