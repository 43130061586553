import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../common/http.service';

@Injectable({
	providedIn: 'root',
})
export class IcomLibsProjectIamAccountService {
	constructor(private apiService: ApiService) { }

	add(data: any): Observable<any> {
		return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixIam}/v1/account/cms/user`, data) as Observable<any>;
	}

	list(data: any): Observable<any> {
		return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixIam}/v1/account/users`, data) as Observable<any>;
	}
}
