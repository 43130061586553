import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyNzFormFieldModule } from '@ngx-formly/ng-zorro-antd/form-field';
import { NzSelectModule } from 'ng-zorro-antd/select';

@Component({
  selector: 'formly-wrapper-nz-select',
  template: `
    <nz-select
      class="w-full"
      nzMode="tags"
      [formControl]="$any(formControl)"
      [formlyAttributes]="field"
      nzPlaceHolder="Nhập và ấn enter"
      (ngModelChange)="props.change && props.change(field, $event)"
    >
      <!-- <nz-option *ngFor="let option of props.options | async" [nzValue]="option.value" [nzLabel]="option.label">
      </nz-option> -->
    </nz-select>
  `,
  standalone: true,
  imports: [
    NzSelectModule,
    CommonModule,
    ReactiveFormsModule,
    NzSelectModule,
    FormlyNzFormFieldModule,
    FormlySelectModule,
    FormsModule,
    FormlyModule
  ]
})
export class FormlyWrapperNzSelectTagsComponent extends FieldWrapper implements OnInit {
  ngOnInit() {
    // Đảm bảo rằng model là một mảng để nz-select có thể hiển thị tags
    if (!Array.isArray(this.formControl.value)) {
      this.formControl.setValue([]);
    }
  }

  onChange(values: any[]): void {
    // Cập nhật giá trị của form control khi có sự thay đổi
    this.formControl.setValue(values);
  }
}
