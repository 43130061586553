import { IEnvironment } from "./environment.model";

export const environment: IEnvironment = {
	production: false,
	module: "mp",
	banner: "assets/imgs/mypoint-banner.png",
	title: "MyPoint Administrator",
	favicon: "assets/imgs/mypoint-logo.png",
	welcome: "Welcome to MyPoint Administrator",
	workSpaceCode: '8854',
	defaultDisplay: 5, 
	apiGetFileStatic: "https://api.sandbox.mypoint.com.vn/",
	apiGatewayUrl: "https://api.sandbox.mypoint.com.vn/8854/",
	merchantUrl: 'http://222.252.26.153:9355/v1/',
	suffixCampaign: "gup2start/admin/rest/campaign-service/api/v2.0/",
	suffixCampaignV3: "gup2start/admin/rest/campaign-service-v3/api/v3.0/",
	suffixOrder: "gup2start/admin/rest/order/api/v1.0/",
	suffixDynamicHome: "gup2start/admin/rest/dynamic-home/api",
	suffixProduct: "gup2start/admin/rest/product/api/",
	suffixAdmin: "/gup2start/admin/rest/user/api/",
	suffixIam: "gup2start/rest/iam/",
};
