import { Component, forwardRef } from '@angular/core';
import { FieldType, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { DefaultValueAccessor, FormControl, FormControlDirective, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { QuillEditorComponent, QuillModule } from 'ngx-quill';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NgIf, NgClass } from '@angular/common';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

@Component({
  selector: 'icom-libs-core-components-formly-quill',
  template: `
    <nz-form-item>
      <nz-form-label *ngIf="to.label" [nzRequired]="to.required">{{ to.label }}</nz-form-label>
      <div class="nz-input-group">
        <quill-editor [formControl]="$any(formControl)" [styles]="{height: '250px'}" [theme]="'snow'" [placeholder]="'Nhập nội dung'" [formlyAttributes]="field" format="html"></quill-editor>
      </div>

      <div class="ant-form-item-explain" [ngClass]="{'ant-form-item-explain-connected' : !showErr(field)}">
        <div class="ant-form-item-explain-error">
          <formly-validation-message [field]="field"></formly-validation-message>
        </div>
      </div>
    </nz-form-item>
  
  `,
  standalone: true,
  imports: [
    NzDatePickerModule,
    ReactiveFormsModule,
    FormlyNgZorroAntdModule,
    FormlyModule,
    NgIf,
    NzFormModule,
    NgClass,
    QuillModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuillEditorComponent),
      multi: true,
    },
    FormControlDirective
  ],
})
export class IcomLibsCoreComponentsFormlyQuillComponent extends FieldType {

  showErr(field: FormlyFieldConfig) {
		return field.formControl && field.formControl.invalid && field.formControl.touched;
	}
}
